/* eslint-disable @next/next/no-img-element */
import Card from '@/components/Card/Card';
import { Locale } from '@/i18n/routing';
import getFeaturedRecents from '@/resources/messages/featuredrecent/featuredrecent';
import { useLocale, useTranslations } from 'next-intl';
import Link from 'next/link';

const FeaturedRecent = () => {
	const locale = useLocale() as Locale;
	const featuredRecents = getFeaturedRecents(locale);
	const translations = useTranslations('Common');

	return (
		<Card title={{ text: 'Meer' }}>
			<ul className="grid items-stretch justify-stretch gap-y-1 lg:grid-cols-2 lg:grid-rows-2">
				{featuredRecents.map((featuredRecent, index) => (
					<li key={index}>
						<Link
							href={featuredRecent.url}
							target="_blank"
							rel="noopener noreferrer"
							className="flex h-full flex-col gap-2 rounded-xl p-1 pt-2 transition-all hover:bg-slate-500/10"
						>
							<img
								src={featuredRecent.image.url}
								alt={featuredRecent.image.alt}
								className="mx-auto h-10 w-10 shrink-0 rounded-full border-2 border-black/10 object-cover lg:h-14 lg:w-14"
							/>
							<div>
								<h4 className="text-balance text-center text-sm text-slate-800 dark:text-slate-300">
									{featuredRecent.title}
								</h4>
								<p className="text-balance text-center">
									{featuredRecent.description}
								</p>
								<span className="sr-only">
									({translations('external_link')})
								</span>
							</div>
						</Link>
					</li>
				))}
			</ul>
		</Card>
	);
};

export default FeaturedRecent;
