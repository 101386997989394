import { Locale } from '@/i18n/routing';

type FeaturedRecent = {
	title: string;
	description: string;
	url: string;
	image: {
		url: string;
		alt: string;
	};
};

const getFeaturedRecents = (locale: Locale): FeaturedRecent[] => {
	return [
		{
			title: locale === 'en' ? 'Prize winner' : 'Prijswinnaar',
			description:
				locale === 'en'
					? 'Het Klokhuis about AI wins Cinekid award'
					: 'Het Klokhuis over AI wint Cinekid award',
			url: 'https://npo.nl/innovatie/nieuws/het-klokhuis-over-ai-ntr-wint-cine-kid-award',
			image: {
				url: '/assets/organizations/cinekid.jpg',
				alt:
					locale === 'en'
						? 'Logo of Cinekid: abstract lines representing a lion.'
						: 'Logo van Cinekid: abstracte lijnen die een leeuw voorstellen.',
			},
		},
		{
			title: locale === 'en' ? 'Nomination' : 'Nominatie',
			description:
				locale === 'en'
					? '"Het Water Komt!" app nominated (Prix Europa)'
					: '"Het Water Komt!" app genomineerd (Prix Europa)',
			url: 'https://npo.nl/innovatie/nieuws/prix-europa-2023',
			image: {
				url: '/assets/organizations/prix-europa.jpg',
				alt:
					locale === 'en'
						? 'Logo of Prix Europa: stars aligned in a circle and a bull overlapping.'
						: 'Logo van Prix Europa: sterren uitgelijnd in een cirkel en een stier die overlappen.',
			},
		},
		{
			title: locale === 'en' ? 'Occupation' : 'Functie',
			description:
				locale === 'en'
					? 'Interaction, innovation & accessibility engineer'
					: 'Interactie, innovatie & toegankelijkheid',
			url: 'https://ntr.nl',
			image: {
				url: '/assets/organizations/ntr.jpg',
				alt:
					locale === 'en'
						? 'NTR logo: lowercase letters with a colon at the end.'
						: 'NTR logo: kleine letters met een dubbele punt aan het einde.',
			},
		},
		{
			title: 'LinkedIn',
			description:
				locale === 'en' ? "Let's connect" : 'Laten we connecten',
			url: 'https://www.linkedin.com/in/tijmenennik/',
			image: {
				url: '/assets/organizations/linkedin.jpg',
				alt:
					locale === 'en'
						? 'LinkedIn logo: lowercase letters I and N.'
						: 'LinkedIn logo: kleine letters I en N.',
			},
		},
	];
};

export default getFeaturedRecents;
