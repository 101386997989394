import { Locale } from '@/i18n/routing';

export interface Talk {
	date: string;
	title: string;
	description: string;
	location: string;
	organization: TalkOrganization;
	canBeFeatured: boolean;
}

interface TalkOrganization {
	name: string;
	logo: {
		url: string;
		alt: string;
	};
	color: string;
}

const getOrganizations = (
	locale: Locale,
): Record<string, TalkOrganization> => ({
	clarify: {
		name: 'Clarify',
		logo: {
			url: '/assets/organizations/clarify.jpg',
			alt:
				locale === 'en'
					? 'Clarify logo: a circle that is blurry on the left and sharp on the right.'
					: 'Clarify logo: een cirkel die links onscherp is en rechts scherp.',
		},
		color: '#f1432c',
	},
	vpro: {
		name: 'VPRO',
		logo: {
			url: '/assets/organizations/vpro.jpg',
			alt:
				locale === 'en'
					? 'VPRO logo: the name spelled out in lowercase. The letters V and R look like they are projected with light coming from the top right.'
					: 'VPRO logo: de naam in kleine letters. De letters V en R lijken alsof ze geprojecteerd zijn met licht dat van rechtsboven komt.',
		},
		color: '#b9202a',
	},
	generation_ai: {
		name: 'Generation AI',
		logo: {
			url: '/assets/organizations/generation-ai.jpg',
			alt:
				locale === 'en'
					? 'Generation AI logo: a blue friendly and floating robot (without legs).'
					: 'Generation AI logo: Het is een blauwe vriendelijke en zwevende robot (zonder benen).',
		},
		color: '#2c6caf',
	},
	dutch_media_week: {
		name: 'Dutch Media Week',
		logo: {
			url: '/assets/organizations/dutch-media-week.jpg',
			alt:
				locale === 'en'
					? 'Dutch Media Week logo: the name spelled out in capital letters. The word "media" is emphasized by an orange color and bold font.'
					: 'Dutch Media Week logo: de naam in hoofdletters. Het woord "media" is geaccentueerd door een oranje kleur en vetgedrukt.',
		},
		color: '#3678E2',
	},
	npo: {
		name: 'NPO',
		logo: {
			url: '/assets/organizations/npo.jpg',
			alt:
				locale === 'en'
					? 'NPO logo: An orange and tilted square with the letters NPO in white.'
					: 'NPO logo: Een oranje en schuin vierkant met de letters NPO in het wit.',
		},
		color: '#ea6a0f',
	},
});

const getTalks = (locale: Locale): Talk[] => {
	return [
		{
			date: '2024-09-12',
			title: 'NPO Innovatie Estafette',
			description:
				locale === 'en'
					? 'At this live Innovatie Estafette I talked about a past, current and future project for Het Klokhuis.\nFirst I talked about the AI Studio: an interactive website where children can learn about AI through play and in a safe environment.\nThen I talked about SoortSafari: an interactive game where children can learn about nature in their own environment.\nThe last project is a project that will be revealed in the future, but it will be about journalism & different perspectives'
					: 'Tijdens deze live Innovatie Estafette vertelde ik over een afgerond, huidig en toekomstig project voor Het Klokhuis.\nEerst vertelde ik over de AI Studio: een interactieve website waar kinderen spelenderwijs en in een veilige omgeving over AI kunnen leren.\nDaarna vertelde ik over SoortSafari: een interactief spel waar kinderen over de natuur in hun eigen omgeving kunnen leren.\nHet laatste project is een project dat in de toekomst onthuld zal worden, maar het zal gaan over journalistiek & verschillende perspectieven.',
			location: 'EO, Hilversum',
			organization: getOrganizations(locale).npo,
			canBeFeatured: true,
		},
		{
			date: '2023-10-02',
			title: 'Dutch Media Week',
			description:
				locale === 'en'
					? 'At the Public Broadcast Talk, I joined a panel as a creator to present my AI projects. My presentation focused on the importance of AI education for children and how AI serves as a catalyst for novel, innovative ideas.'
					: 'Tijdens de Publieke Omroep Talk zat ik in een panel als maker, waar ik mijn AI-projecten presenteerde. Ik deelde mijn inzichten over het onderwijzen van kinderen over AI en besprak hoe AI bijdraagt aan mijn innovatieve concepten.',
			location: 'Beeld en Geluid, Hilversum',
			organization: getOrganizations(locale).dutch_media_week,
			canBeFeatured: true,
		},
		{
			date: '2023-04-20',
			title: 'Clarify Community Event',
			description:
				locale === 'en'
					? 'A talk about two of my research projects on using AI for gamification at Het Klokhuis. The goal of this talk is to inspire and give insight into the research process.'
					: 'Hier presenteerde ik over twee van mijn onderzoeken over het gebruiken van AI voor gamification bij Het Klokhuis. Het doel van deze presentatie was om te inspireren en inzicht te geven in het onderzoeksproces.',
			location: 'Clarify, Amsterdam',
			organization: getOrganizations(locale).clarify,
			canBeFeatured: true,
		},
		{
			date: '2022-11-29',
			title: 'VPRO Intern',
			description:
				locale === 'en'
					? 'During this internal presentation I talked about my research on AR on the web for Het Klokhuis, the AI Studio I developed and the Podwalks CMS I built. The goal of this presentation was to inspire and give insight into how the projects went.'
					: 'Tijdens deze interne presentatie vertelde ik over mijn onderzoek naar AR op het web voor Het Klokhuis, de AI Studio die ik ontwikkelde en het Podwalks CMS dat ik bouwde. Het doel van deze presentatie was inspireren en inzicht geven in hoe de projecten verliepen.',
			location: 'VPRO Villa, Hilversum',
			organization: getOrganizations(locale).vpro,
			canBeFeatured: false,
		},
		{
			date: '2022-11-10',
			title: 'Rijksuniversiteit Groningen',
			description:
				locale === 'en'
					? 'Dagmar Heeg (PhD student Rijksuniversiteit Groningen) asked me to talk about my research on teaching children about AI. The presentation was for her project: Generation AI. During the project I developed an AI Studio for Dutch television show Het Klokhuis, where children learn through play: they train their own algorithms.'
					: 'Dagmar Heeg (PhD student Rijksuniversiteit Groningen) vroeg mij om te vertellen over mijn onderzoek naar het leren van kinderen over AI. De presentatie was voor haar project: Generatie AI. Tijdens het project ontwikkelde ik een AI Studio voor Het Klokhuis, waar kinderen spelend leren over AI: ze trainen hun eigen algoritmes.',
			location: 'RuG, Groningen',
			organization: getOrganizations(locale).generation_ai,
			canBeFeatured: true,
		},
		{
			date: '2022-10-03',
			title: 'Dutch Media Week',
			description:
				locale === 'en'
					? 'At the museum Beeld en Geluid, I presented my research about AR on the web for Het Klokhuis. During the experience, children learned about their invisible pets.'
					: 'Bij het museum Beeld en Geluid presenteerde ik mijn onderzoek naar AR op het web voor Het Klokhuis. Tijdens de beleving leerde kinderen hun onzichtbare huisdieren kennen.',
			location: 'Beeld en Geluid, Hilversum',
			organization: getOrganizations(locale).dutch_media_week,
			canBeFeatured: true,
		},
		{
			date: '2022-09-15',
			title: 'NPO Innovatie Estafette',
			description:
				locale === 'en'
					? 'At this live Innovatie Estafette I talked about my graduation research on teaching children about AI. The project that came out of this was the AI Studio for Dutch television show Het Klokhuis.'
					: 'Bij deze live Innovatie Estafette vertelde ik over mijn afstudeeronderzoek naar spelend leren over AI. Het project dat hieruit voortkwam was de AI Studio voor Het Klokhuis.',
			location: 'NPO, Hilversum',
			organization: getOrganizations(locale).npo,
			canBeFeatured: true,
		},
	].sort((a, b) => {
		const dateA = new Date(a.date);
		const dateB = new Date(b.date);

		if (dateA > dateB) {
			return -1;
		}

		if (dateA < dateB) {
			return 1;
		}

		return 0;
	});
};

export const getFeaturedTalks = (locale: Locale): Talk[] =>
	getTalks(locale)
		.filter((talk) => talk.canBeFeatured)
		.slice(0, 2);

export default getTalks;
