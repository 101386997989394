import { Dialog } from '@headlessui/react';
import { motion, useReducedMotion } from 'motion/react';
import IconButton from '@/components/IconButton/IconButton';
import { mdiCloseCircle } from '@mdi/js';
import { useTranslations } from 'next-intl';
import EmojiText from '@/components/EmojiText/EmojiText';

const BasicDialog = ({
	isOpened,
	setIsOpened,
	title,
	children,
}: {
	isOpened: boolean;
	setIsOpened: (isOpened: boolean) => void;
	title: string;
	children: React.ReactNode;
}) => {
	const reduceMotion = useReducedMotion();
	const translations = useTranslations('BasicDialog');

	return (
		<Dialog
			open={isOpened}
			onClose={() => setIsOpened(false)}
			className="fixed inset-0 z-50 overflow-hidden bg-black/10"
			onClick={() => {
				setIsOpened(false);
			}}
		>
			<Dialog.Overlay className="fixed inset-0 z-10 overflow-hidden backdrop-blur-md">
				<div className="bg-cornflower/25 h-full w-full" />
			</Dialog.Overlay>
			<Dialog.Panel
				className="fixed inset-0 z-50 h-screen overflow-y-auto overflow-x-hidden p-4"
				onClick={() => setIsOpened(false)}
			>
				<motion.div
					className="grid h-full max-h-screen w-full items-center justify-center"
					initial={{
						opacity: 0,
						scale: reduceMotion ? 1 : 0.7,
					}}
					animate={{
						opacity: 1,
						scale: 1,
					}}
				>
					<motion.div
						className="relative grid max-w-xl gap-4 rounded-3xl border-2 border-black/10 bg-white/90 p-6 shadow-lg md:rounded-tr-lg md:text-lg dark:bg-slate-800/90"
						onClick={(event) => event.stopPropagation()}
						tabIndex={-1}
					>
						<IconButton
							icon={mdiCloseCircle}
							label={translations('close_button')}
							onClick={() => {
								setIsOpened(false);
							}}
							className="fixed right-2 top-2 z-10 sm:absolute sm:-right-4 sm:-top-4"
						/>
						<Dialog.Title className="text-cornflower-800 dark:text-cornflower-300 pe-4 text-2xl font-bold">
							<EmojiText>{title}</EmojiText>
						</Dialog.Title>
						<div className="flex flex-col gap-4">{children}</div>
					</motion.div>
				</motion.div>
			</Dialog.Panel>
		</Dialog>
	);
};

export default BasicDialog;
