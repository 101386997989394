'use client';

import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { motion, useReducedMotion } from 'motion/react';
import { useTranslations } from 'next-intl';
import EmojiText from '@/components/EmojiText/EmojiText';
import BasicDialog from '../BasicDialog/BasicDialog';

const MessageBubble = ({
	message,
	expandedMessage,
	rotation = 0,
	className,
}: {
	message: string;
	expandedMessage: string;
	rotation?: number;
	className?: string;
}) => {
	const reduceMotion = useReducedMotion();
	const translations = useTranslations('MessageBubble');

	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<>
			<motion.button
				// layoutId={reduceMotion ? undefined : id}
				// layoutDependency={isExpanded}
				className={twMerge(
					'relative block max-w-[18rem] px-6 py-4 text-balance rounded-3xl max-md:rounded-tl-lg md:rounded-tr-lg border-2 border-black/10 bg-white/80 dark:bg-slate-700/70 text-start md:text-lg shadow-lg cursor-pointer',
					className,
				)}
				onClick={() => setIsExpanded(true)}
				whileHover={{
					scale: reduceMotion ? 1 : 1.05,
				}}
				whileTap={{
					scale: reduceMotion ? 1 : 0.9,
				}}
				initial={{
					rotate: rotation,
				}}
				title={translations('expand')}
				aria-expanded={isExpanded}
			>
				<EmojiText>{message}</EmojiText>
			</motion.button>
			<BasicDialog
				isOpened={isExpanded}
				setIsOpened={setIsExpanded}
				title={message}
			>
				{expandedMessage.split('\n').map((paragraph, index) => (
					<span key={index}>
						<EmojiText>{paragraph}</EmojiText>
					</span>
				))}
			</BasicDialog>
			{/* <Dialog
				open={isExpanded}
				onClose={() => setIsExpanded(false)}
				className="fixed inset-0 z-50 bg-black/10"
				onClick={() => {
					setIsExpanded(false);
				}}
			>
				<Dialog.Overlay className="fixed inset-0 z-10 backdrop-blur-md">
					<div className="bg-cornflower/25 h-full w-full" />
				</Dialog.Overlay>
				<Dialog.Panel
					className="fixed inset-0 z-50 h-screen overflow-y-auto p-4"
					onClick={() => setIsExpanded(false)}
				>
					<div className="grid h-full max-h-screen w-full items-center justify-center">
						<AnimatePresence>
							{isExpanded && (
								<motion.div
									// layoutId={reduceMotion ? undefined : id}
									// layoutDependency={isExpanded}
									initial={{
										opacity: 0,
										scale: reduceMotion ? 1 : 0.7,
									}}
									animate={{
										opacity: 1,
										scale: 1,
									}}
									exit={{
										opacity: 0,
										scale: reduceMotion ? 1 : 0.7,
									}}
									className="relative grid max-w-xl gap-4 rounded-3xl border-2 border-black/10 bg-white/90 p-6 shadow-lg dark:bg-slate-800/90 md:rounded-tr-lg md:text-lg"
									onClick={(event) => event.stopPropagation()}
									tabIndex={-1}
								>
									<IconButton
										icon={mdiCloseCircle}
										label={translations('collapse')}
										onClick={() => {
											setIsExpanded(false);
										}}
										className="fixed right-2 top-2 sm:absolute sm:-right-4 sm:-top-4"
									/>
									<Dialog.Title className="text-cornflower-800 dark:text-cornflower-400 pe-4 text-2xl font-bold">
										<EmojiText>{message}</EmojiText>
									</Dialog.Title>
									<Dialog.Description className="grid gap-4">
										{expandedMessage
											.split('\n')
											.map((paragraph, index) => (
												<span key={index}>
													<EmojiText>
														{paragraph}
													</EmojiText>
												</span>
											))}
									</Dialog.Description>
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				</Dialog.Panel>
			</Dialog> */}
		</>
	);
};

export default MessageBubble;
