'use client';

import MessageBubble from '@/components/MessageBubble/MessageBubble';
import ProfessionButton from '@/components/ProfessionButton/ProfessionButton';
import Arrow from '@/resources/assets/Arrow';
import Avatar from '@/resources/assets/Avatar';
import Logo from '@/resources/assets/Logo';
import { useTranslations } from 'next-intl';
import Talks from './Talks';
import FeaturedRecent from './FeaturedRecent';
import Portfolio from '@/components/Portfolio/Portfolio';
import { motion, useReducedMotion } from 'motion/react';

export default function Home() {
	const translations = useTranslations('Home');

	const reduceMotion = useReducedMotion();

	return (
		<motion.main
			className="relative mx-auto flex min-h-screen max-w-6xl flex-col overflow-y-auto overflow-x-hidden p-8 md:p-16 lg:p-24"
			initial={{
				opacity: 0,
				y: reduceMotion ? 0 : '2rem',
			}}
			animate={{
				opacity: 1,
				y: 0,
			}}
		>
			<header>
				<h1 className="sr-only">Tijmen Ennik</h1>
				<div className="flex w-full flex-col items-center justify-center max-md:gap-4 md:flex-row">
					<Logo className="max-h-32 max-w-md max-md:rotate-6 md:max-h-80 lg:max-w-lg lg:shrink-0" />
					<Avatar className="max-w-[16rem] lg:max-w-xs lg:shrink-0" />
				</div>
				<MessageBubble
					message={translations('introduction')}
					expandedMessage={translations('introduction_expanded')}
					className="mx-auto lg:-mt-8"
					rotation={-1}
				/>
			</header>
			<ul
				aria-label={translations('professions')}
				className="mx-auto mt-8 flex max-w-md flex-wrap items-center justify-center gap-6"
			>
				<li>
					<ProfessionButton
						profession={translations('interaction_engineer')}
						description={translations(
							'interaction_engineer_description',
						)}
						className="bg-cornflower-500 text-white"
						dialogClassName="bg-cornflower-600 text-white"
						rotation={-1}
					/>
				</li>
				<li>
					<ProfessionButton
						profession={translations('ai')}
						description={translations('ai_description')}
						className="bg-fuchsia-500 text-white"
						dialogClassName="bg-fuchsia-600 text-white"
						rotation={1}
					/>
				</li>
				<li>
					<ProfessionButton
						profession={translations('full_stack_developer')}
						description={translations(
							'full_stack_developer_description',
						)}
						className="bg-picton-600 text-white"
						dialogClassName="bg-picton-700 text-white"
						rotation={2}
					/>
				</li>
				<li>
					<ProfessionButton
						profession={translations('accessibility')}
						description={translations('accessibility_description')}
						className="bg-rose-500 text-white"
						dialogClassName="bg-rose-600 text-white"
						rotation={-5}
					/>
				</li>
			</ul>
			<Arrow variation="up_01" className="mx-auto mt-4 w-8" />
			<MessageBubble
				message={translations('professions_message')}
				expandedMessage={translations('professions_message_expanded')}
				className="mx-auto"
				rotation={1}
			/>
			<div className="font-comic relative mx-auto w-full max-w-sm text-2xl text-rose-600 sm:mt-16 dark:text-rose-300">
				<Arrow
					variation="down_02"
					className="absolute right-0 hidden w-[32rem] sm:block lg:-mt-4 lg:rotate-3"
				/>
				<h2 className="pt-10 text-center tracking-wide">
					{translations('history_and_events_title')}
					<span aria-hidden>...</span>
				</h2>
			</div>
			<div className="my-8 grid gap-4 md:grid-cols-2">
				<Talks />
				<FeaturedRecent />
			</div>
			<div className="font-comic relative mx-auto w-full max-w-sm text-2xl text-rose-600 sm:mt-16 dark:text-rose-300">
				<Arrow
					variation="down_03"
					className="-mb-8 h-16 w-96 max-w-full lg:-mt-4"
				/>
				<h2 className="pt-10 text-center tracking-wide">
					{translations('projects_title')}
				</h2>
			</div>

			<Portfolio />
		</motion.main>
	);
}
